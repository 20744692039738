.lp-container {
    max-width: 1280px;
    margin: auto;
    padding: 80px;
    background: #E5E5E5;
}

/*.lp-wrapper{*/
/*    max-width: 1164px;*/
/*    margin: auto;*/
/*}*/

.lp-section1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px
}

.lp-section2 {
    display: flex;
    justify-content: space-evenly;
    margin: auto;
    margin-bottom: 100px;

}

.lp-section2-div1 {
    max-width: 534px;
    background: #FFFFFF;
    border-radius: 24px;
    padding-left: 13px;
    padding-right: 13px;
    padding-bottom: 15px;
    margin-right: 10px;
}

.lp-section2-h1 {
    font-family: 'Circular-Std';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.0015em;
    margin-bottom: 16px;
}

.lp-section2-p {
    font-family: 'Circular-Std';
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.0125em;
    color: #7A7C7C;
    margin-bottom: 40px;
}

.lp-section2-img {
    width: 100%;
    /*height: 310px;*/
}

.lp-section3 {
    background: #FFFFFF;
    border-radius: 24px;
    margin-bottom: 100px;
    padding: 48px 54px;
}

.lp-section3-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.lp-section3-div1 {
    width: 30%;
    height: 248px;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    padding-right: 15px;
}

.mini-div {
    height: 80px;
    max-width: 358px;
    margin: auto;
    text-align: center;
    margin-bottom: 40px;
}

.lp-section3-h2 {
    font-family: 'Circular-Std';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 24px;
    letter-spacing: 0.0015em;
    color: #2C2C2C;
}

.lp-section3-p {
    font-family: 'Circular-Std';
    font-style: normal;
    font-weight: 450;
    font-size: 24px;
    line-height: 32px;
    color: #626462;
    letter-spacing: 0.015em;
}

.lp-section3-link {
    font-family: 'Circular-Std';
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.0125em;
    color: #71C64D;
    margin-top: 16px;
}

.lp-section3-div2 {
    width: 70%;
}

.lp-section3-img {
    width: 100%;
    height: 273px;
}

.lp-download-app-wrapper {
    background: #FFFFFF;
    border-radius: 24px;
    margin-bottom: 100px;
    padding: 40px 0;
}

.lp-section4 {
    background: #FFFFFF;
    border-radius: 24px;
    margin-bottom: 100px;
    padding-left: 45px;
}

.lp-section4-img {
    border-radius: 20px;
    width: 100%;
}

.lp-input-container {
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-top: 60px;
    margin-bottom: 40px;
    max-width: 435px;
    height: 48px;
    border-radius: 24px;
    border: 1px solid #71C64D;
}

.lp-input {
    border: none;
    color: #C1C5C7;
    letter-spacing: 0.0015em;
    font-family: 'Circular-Std';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    padding-left: 20px;
    border-radius: 24px;
}

.lp-btn {
    width: 198px;
    border: none;
    font-family: 'Circular-Std';
    font-style: normal;
    font-weight: 500;
    background: #71C64D;
    border-radius: 16px;
    color: white;
    cursor: pointer;
    font-size: 16px;
    line-height: 32px;

}

.lp-section5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 130px;
}

.jjc-logo-footer {
    width: 217px;
    height: 59px;
    /*margin-bottom: 67px;*/
}

.lp-section5-list {

}

.lp-list {
    display: inline-block;
    margin: 15px;
    font-family: 'Circular-Std';
    font-style: normal;
    font-weight: 450;
    font-size: 19px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.0125em;
    color: #2C2C2C;
}

.lp-font-wrapper {
    display: flex;
    margin-bottom: 30px;
}

.fa-icon {
    background: transparent;
    color: #71C64D;
    font-size: 40px;
    margin: 0px 30px 0px 30px;

}

.lp-section5-p {
    font-family: 'Circular-Std';
    font-style: normal;
    font-weight: 450;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.0125em;
    color: #2C2C2C;
    margin-bottom: 30px;
}

.lp-section5-p2 {
    font-family: 'Circular-Std';
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.0015em;
    color: #2C2C2C;
}

@media screen and (max-width: 820px) and (min-width: 470px) {
    .lp-section2 {
        justify-content: space-between;
        margin: auto;
        margin-bottom: 100px;
        width: 100%;
    }

    .lp-section2-div1 {
        width: 50%;
    }

    .lp-section2-h1 {
        font-size: 28px;
    }

    .lp-section2-p {
        font-size: 12px;
    }

    .lp-section3-div1 {
        width: 50%;
    }

    .lp-section3-div2 {
        width: 50%;
    }

    .lp-section3-h2 {
        font-size: 28px;
    }

    .lp-section3-p {
        font-size: 20px;
    }
}

@media screen and (max-width: 470px) {
    .lp-section2 {
        display: block;
    }

    .mini-div {
        margin-bottom: 20px;
    }

    .lp-section2-div1 {
        padding-top: 10px;
        margin-bottom: 45px;
    }

    .lp-section2-h1 {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .lp-section3-div {
        display: block;
    }

    .lp-section3-div1 {
        width: 100%;
        display: block;
    }

    .lp-section3-div2 {
        width: 100%;
    }

    .lp-section3-h2 {
        font-size: 20px;
    }

    .lp-section3-p {
        font-size: 15px;
    }

    .lp-section3-img {
        object-fit: contain;
        height: 60px;
    }

    .lp-input-container {
        display: none;
    }

    .lp-list {
        font-size: 14px;
    }

    .fa-icon {
        font-size: 25px;
    }

    .lp-section5-p {
        font-size: 14px;
    }

    .lp-section5-p2 {
        font-size: 10px;
    }

}

*:focus{
    outline: none !important;
}