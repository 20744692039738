.App {
  text-align: center;

}

.search-homepage{
  height: 100vh;
  background-image: url("./assets/images/background_image.jpg");
  /*filter: brightness(0.6);*/
  display: flex;
  justify-content: center;
  background-position: center;
  /*align-items: center;*/
  position: relative;
}
.map-container {
  /*height: 400px;*/
}
.search-container{
  max-width: 800px;
  flex: 1;
  filter: brightness(1);
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 250px;
}
.search-box-container{
  position: absolute;
  top: 20px;
  z-index: 9;
}
.map-search-container{
  max-width: 400px;
  flex: 1;
  filter: brightness(1);
  padding-left: 10px;
  padding-right: 10px;
}

.search-input{
  background: white;
  width: 100%;
}
.mapboxgl-map{
  height: 100vh;
}

.popup-title{
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 17px;
}

.popup-description{
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
  font-weight: 300;
  margin-top: 8px;
  margin-bottom: 8px;
}

.mapbox-directions-component-keyline{
  display: none;
}

.mapbox-directions-profile{
  display: block;
}
/*.MuiButton-outlinedPrimary {*/
/*  text-transform: none;*/
/*}*/

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


iframe {
  pointer-events: none;
}